<template>
  <v-row>
    <v-col cols="12">
      <DataTable
        :height="$vuetify.breakpoint.md ? undefined : 'calc(100vh - 260px)'"
        :form="form"
        :table-data="tableData"
        :pagination="pagination"
        :params="params"
        :options="options"
        @on-reset="reset"
        @on-search="getData(1)"
        @on-edit="showDialogForm('edit', $event)"
        @on-create="showDialogForm('create')"
        @on-export="exportData"
        @deleted="getData()"
        @updated="getData()"
        @refreshed="getData()"
        @sorted="handleSort"
      />
    </v-col>
    <v-col cols="12">
      <Pagination
        :length="pagination.lastPage"
        :total="pagination.total"
        :all="pagination.all"
        :params="params"
        @onPageChange="getData"
        @onPerPageChange="getData(1)"
      />
    </v-col>
  </v-row>
</template>

<script>
import DataTable from './components/DataTable.vue'
import Pagination from '@/components/Pagination.vue'
import {
  getChangeDetectionSets,
  getChangeDetectionResults,
  exportResults,
  getResultCount,
} from '@/api/change-detection'
import { getCdStatuses, getCdClassificationTypes, getCdTypes } from '@/api/category'
import indexMixin from '@/mixins/crud/index'

export default {
  components: { DataTable, Pagination },
  props: {
    statuses: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [
    indexMixin(
      async params => {
        const [
          { data, meta },
          {
            data: { count },
          },
        ] = await Promise.all([getChangeDetectionResults(params), getResultCount()])
        meta.all = count
        return { data, meta }
      },
      {
        cdSets: {
          func: getChangeDetectionSets,
          params: { select: 'id*name', sortBy: 'id' },
        },
        cdStatuses: {
          func: getCdStatuses,
        },
        cdClassificationTypes: {
          func: getCdClassificationTypes,
        },
        cdTypes: {
          func: getCdTypes,
        },
      },
      exportResults,
    ),
  ],

  data() {
    return {
      model: 'change-detection',
      showDialog: false,
      editing: false,
      defaultParams: {
        page: 1,
        perPage: 20,
        sortBy: 'id:desc',
        with: 'ward:id*name;cdSet:id*name;cdClassType:id*name;cdStatus:id*name',
        action: null,
        cdSets: [],
        wards: [],
        plots: [],
        statuses: [],
        classTypes: [],
        types: [],
        landTypes: [],
      },
      form: {},
    }
  },
  created() {
    if (this.statuses.length > 0) {
      this.defaultParams.statuses = this.statuses
    }
    this.resetQueryParams()
  },
}
</script>
